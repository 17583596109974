import React, { useState, useEffect } from 'react';
import MarkDown from 'markdown-to-jsx';
import { Helmet } from 'react-helmet';

import "./Article.css"

export default function Article(props) {
    let {
        id,
        title,
        shortTitle,
        fileName = null,
        folder = null,
        webSitePagePath,
        websiteSection,
        weight,
        date = "16.02.2022",
        tags,
        language,
        description,
        readTime,
        author,
    } = props;
    // 'js-settimeout-setinterval'


    // -----------------------------
    // import(`../../markdown/${fileName}.md`)
    //        '../../markdown/js-settimeout-setinterval.md'
    // folder ? `../../markdown/${folder}/${fileName}.md` : `../../markdown/${fileName}.md`
    // я блять не понимаю
    // -----------------------------

    let fullFolderPath = folder ? ("markdown/" + folder + "/" + fileName) : ("markdown/" + fileName);
    // -----------------------------

    const [post, setPost] = useState('');
    useEffect(() => {
        import(`../../${fullFolderPath}.md`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setPost(res));
            })
            .catch(err => console.log("Ошибка при фетче", err));
    }, [fileName]);

    const result = (
        <MarkDown>
            {post}
        </MarkDown>
    );

    return (
        <>
            <Helmet>
                <title>{props.title || "article"}</title>
                <meta name="description" content={props.description || "test-description"} />
                <meta name="theme-color" content="#000000" />
            </Helmet>
            <article>
                {result}
            </article>
        </>
    );
}
